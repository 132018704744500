:root {
    --breadcrumbs-wrap: nowrap;
}

.content-pane .content-pane,
.content-pane .header-pane {
    --horizontal-margin: 0;
}

.header-pane {
    display: var(--header-pane-display, flex);
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: @app-bar-height;
    align-items: center;
    padding: 0 var(--horizontal-margin, @gutter);

    >* {
        flex-shrink: 0; // default to no shrinkage
    }

    >*:not(:first-child):not(.right):not(.left) {
        margin-left: 0.5rem;
    }

    .left {
        margin-right: auto;
    }

    .right {
        margin-left: auto;
    }

    h1 {
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 20px;
        font-weight: 400;
        margin: auto 0;
    }

    .header-buttons {
        margin-left: auto;

        >* {
            margin-left: @space-3;
        }
    }

    +.content-pane {
        margin-top: var(--content-pane-margin-top, @app-bar-height);
    }
}

.content-pane {
    --panel-position: absolute;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    margin: 0 var(--horizontal-margin, @gutter);
    margin-top: var(--content-pane-margin-top, 0);
}

.uf-app-bar h1 {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 400;
    margin: auto 0;
}

.overlay-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}